var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nav-item-wrapper"},[(_vm.link.text === 'Bli kund')?_c('b-nav-item',{attrs:{"href":_vm.link.href,"target":"_blank","rel":_vm.link.rel},on:{"click":function($event){return _vm.clickHref(_vm.link)}}},[_c('b-button',{staticClass:"hover-dark active-dark",attrs:{"variant":"purple"}},[_vm._v(" "+_vm._s(_vm.link.text)+" ")])],1):(_vm.link.href)?_c('b-nav-item',{attrs:{"href":_vm.link.href,"rel":_vm.link.rel,"target":"_blank"},on:{"click":function($event){return _vm.clickHref(_vm.link)}}},[_c('b-button',{staticClass:"hover-dark active-pink"},[_vm._v(" "+_vm._s(_vm.link.text)+" ")])],1):(_vm.link.text === 'Fakturerings-& bokföringsprogram')?_c('b-nav-item',{class:{
    purpleHover: _vm.currentPath === _vm.link.route
  },attrs:{"rel":_vm.link.rel},on:{"mouseover":function($event){return _vm.displayUnder(true)},"mouseleave":function($event){return _vm.displayUnder(false)},"click":function($event){return _vm.handleClick(_vm.link)}}},[_c('b-nav-text',{class:{
    active: _vm.currentPath === _vm.link.route && _vm.currentPath != 'login'
  }},[_vm._v(" "+_vm._s(_vm.link.text)+" ")])],1):_c('b-nav-item',{class:{
    purpleHover: _vm.currentPath === _vm.link.route
  },attrs:{"rel":_vm.link.rel},on:{"click":function($event){return _vm.handleClick(_vm.link)}}},[_c('b-nav-text',{class:{
    active: _vm.currentPath === _vm.link.route && _vm.currentPath != 'login'
  }},[_vm._v(" "+_vm._s(_vm.link.text)+" ")])],1),(_vm.link.text === 'Fakturerings-& bokföringsprogram')?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.viewWidth < _vm.xl || (_vm.viewWidth >= _vm.xl && _vm.displayUnderMenu)),expression:"viewWidth < xl || (viewWidth >= xl && displayUnderMenu)"}],on:{"mouseover":function($event){return _vm.displayUnder(true)},"mouseleave":function($event){return _vm.displayUnder(false)}}},[_c('ProductMenu',{attrs:{"navOpen":_vm.navOpen},on:{"update-nav-open":_vm.updateNavOpen}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }